import { useSession } from '@faststore/sdk'
import type {
  FullScreenQueryQuery,
  FullScreenQueryQueryVariables,
} from '@generated/graphql'
import type { PageProps } from 'gatsby'
import { graphql } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
// import LazyRenderCMS from 'src/components/LazyRenderCMS'
import RenderCMS from 'src/components/RenderCMS'
import { RenderClient } from 'src/components/RenderClient'
import { mark } from 'src/sdk/tests/mark'

type Props = PageProps<
  FullScreenQueryQuery,
  FullScreenQueryQueryVariables,
  unknown
> & { slug: string }

function Page(props: Props) {
  const {
    data: { site, cmsFlyerFullScreen },
  } = props

  const cmsData = cmsFlyerFullScreen

  const title =
    cmsData?.seo?.siteMetadataWithSlug?.title ?? site?.siteMetadata?.title ?? ''

  const titleTemplate = site?.siteMetadata?.titleTemplate
  const description =
    cmsData?.seo?.siteMetadataWithSlug?.description ??
    site?.siteMetadata?.description ??
    ''

  const seoSlug = cmsData?.seo?.siteMetadataWithSlug?.slug
  const { locale } = useSession()
  const canonical =
    cmsData?.config?.slugAndFilterConfig?.canonical ??
    `${site?.siteMetadata?.siteUrl}${seoSlug}`

  return (
    <RenderClient>
      {/* SEO */}
      <GatsbySeo
        title={title ?? ''}
        titleTemplate={titleTemplate ?? ''}
        description={description ?? ''}
        canonical={canonical}
        language={locale}
        openGraph={{
          type: 'website',
          title,
          description,
        }}
      />
      <section className="page__section cms-departament-components">
        <RenderCMS sections={cmsData?.sections} />
        {/* <LazyRenderCMS sections={cmsData?.sections} /> */}
      </section>
    </RenderClient>
  )
}

export const querySSG = graphql`
  query FullScreenQuery($slug: String) {
    site {
      siteMetadata {
        titleTemplate
        title
        description
        siteUrl
      }
    }
    cmsFlyerFullScreen(
      config: { slugAndFilterConfig: { slug: { eq: $slug } } }
    ) {
      sections {
        data
        name
      }
      seo {
        siteMetadataWithSlug {
          slug
          description
          title
          titleTemplate
        }
      }
      config {
        slugAndFilterConfig {
          slug
          canonical
          noLayout
        }
      }
    }
  }
`

Page.displayName = 'Page'
export default mark(Page)
